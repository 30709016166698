import React, { useState, useContext, useRef, useEffect } from 'react';
import { FaChevronRight, FaChartPie, FaIndustry, FaCheck, FaPrint, FaList } from 'react-icons/fa';
import { FiArrowLeft } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { Context } from '../../Context/AuthContext';
import { Modal, Button } from 'react-bootstrap';
import Loading from '../../pages/Loading';
import { useHistory } from 'react-router-dom';
import './styles.css';
import api from '../../services/api';


const Sidebar = (props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [dropdownOpen3, setDropdownOpen3] = useState(false);
    const toggleDropdown = () => { setDropdownOpen(!dropdownOpen); setDropdownOpen2(false) };
    const toggleDropdown2 = () => { setDropdownOpen2(!dropdownOpen2); setDropdownOpen(false) };
    const toggleDropdown3 = () => { setDropdownOpen3(!dropdownOpen2); setDropdownOpen(false); setDropdownOpen2(false) };
    const [modalShow, setModalShow] = useState(false)
    const [modalShow2, setModalShow2] = useState(false)
    const [modalShow3, setModalShow3] = useState(false)
    const [modalShow4, setModalShow4] = useState(false)
    const sidebarRef = useRef(); // Ref para o elemento do sidebar

    const { visible, handleClose } = props
    const { Admin, FullLicensed, Licensed, Aud } = useContext(Context);


    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`sidebarLeft ${dropdownOpen ? 'show' : dropdownOpen2 ? 'show2' : dropdownOpen3 ? 'show3' : ''}`} style={{ width: visible ? '18%' : '0px', marginLeft: visible ? '0px' : '-200%' }} ref={sidebarRef}>
            <Estabelecimentos show={modalShow} onHide={() => setModalShow(false)} />
            <Tarefas show={modalShow2} onHide={() => setModalShow2(false)} />
            <Riscos show={modalShow3} onHide={() => setModalShow3(false)} />
            <Planos show={modalShow4} onHide={() => setModalShow4(false)} />
            {visible && (
                <>
                    <div className="back-icon" >
                        <i onClick={handleClose}><FiArrowLeft /></i>
                    </div>


                    {FullLicensed && (
                        <div className="drop-toggle" onClick={toggleDropdown}>
                            <div className="sidebar-item">
                                <FaChartPie />
                                <span style={{ paddingLeft: '5px' }}> Dashboards </span>
                                <FaChevronRight className={`arrow ${dropdownOpen ? 'down' : ''}`} />
                            </div>
                        </div>)
                    }

                    {FullLicensed && (
                        <div className={"drop-menu"}>

                            <Link disabled={!Licensed && !Admin && !FullLicensed} to="/dashboardRisco">
                                <div className="sidebar-item">
                                    Gestão de riscos
                                </div>
                            </Link>

                            <Link disabled={!Licensed && !Admin && !FullLicensed} to="/dashboardPlano">
                                <div className="sidebar-item">
                                    Planos de ação
                                </div>
                            </Link>

                        </div>
                    )}

                    {(Licensed && !Aud) && (
                        <div className="drop-toggle" onClick={toggleDropdown2}>
                            <div className="sidebar-item">
                                <FaIndustry />
                                <span style={{ paddingLeft: '5px' }}> Links úteis</span>
                                <FaChevronRight className={`arrow ${dropdownOpen2 ? 'down' : ''}`} />
                            </div>
                        </div>)
                    }

                    {(Licensed && !Aud) && (
                        <div className={"drop-menu2"}>

                            <Link disabled={!Licensed && !Admin && !FullLicensed} to="/gerenciarempresas">
                                <div className="sidebar-item">
                                    Empresas
                                </div>
                            </Link>

                            <div onClick={() => { setModalShow(Licensed || Admin || FullLicensed ? true : false); handleClose() }} className="sidebar-item">
                                Organizações
                            </div>

                            <div onClick={() => { setModalShow2(Licensed || Admin || FullLicensed ? true : false); handleClose() }} className="sidebar-item">
                                Tarefas/Atividades
                            </div>

                            <div onClick={() => { setModalShow3(Licensed || Admin || FullLicensed ? true : false); handleClose() }} className="sidebar-item">
                                Riscos
                            </div>

                            <div onClick={() => { setModalShow4(Licensed || Admin || FullLicensed ? true : false); handleClose() }} className="sidebar-item">
                                Planos de ação
                            </div>

                        </div>
                    )}


                    {FullLicensed && (
                        <Link to="/reavaliacaopgr" disabled={!Licensed && !Admin && !FullLicensed}>
                            <div className="sidebar-item">
                                <FaList /><span style={{ paddingLeft: '5px' }}>Reavaliação PGR</span>
                            </div>
                        </Link>
                    )}
                        

                    {(FullLicensed || Aud) && (
                        <Link to="/auditoria" disabled={!Licensed && !Admin && !FullLicensed}>
                            <div className="sidebar-item">
                                <FaCheck /><span style={{ paddingLeft: '5px' }}>Auditoria</span>
                            </div>
                        </Link>
                    )}

                    <Link to="/GerarRelatorio" disabled={!Licensed && !Admin && !FullLicensed}>
                        <div className="sidebar-item">
                            <FaPrint /><span style={{ paddingLeft: '5px' }}>Gerar relatório</span>
                        </div>
                    </Link>

                </>
            )}
        </div>
    );
};


function Estabelecimentos(props) {

    const history = useHistory()
    const [empresas, setEmpresas] = useState([])
    const [empresaId, setEmpresaId] = useState('')
    const [carregando, setCarregando] = useState(false)
    const { aviso } = React.useContext(Context);

    useEffect(() => {
        if (props.show) {
            async function iniciar() {

                try {
                    setCarregando(true)
                    await api.get('empresa').then(response => {
                        setEmpresas(response.data.resultado)
                    })
                } catch (error) {
                    aviso('error', error)
                } finally {
                    setCarregando(false)
                }

            } iniciar();

        }
    }, [props.show])

    function handleControle() {
        sessionStorage.setItem('empresaId', empresaId)
        sessionStorage.setItem('empresaName', empresas.filter(item => item.id === parseInt(empresaId))[0].razao_social)
        props.onHide()
        history.push('/GerenciarEstabelecimentos')
    }


    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Organizações
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loading loading={carregando} message='Carregando...' />
                <div className="qual-relatorio-gerar2">
                    <div className="selecionarempresa-tarefa2">
                        <label htmlFor="selecionarempresa-tarefa2">Selecione a empresa</label>
                        <select value={empresaId} onChange={e => setEmpresaId(e.target.value)}>
                            <option value="" disabled>Selecione</option>
                            {empresas.map(empresa => (
                                <option key={empresa.id} value={empresa.id} >{empresa.razao_social}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={!empresaId} onClick={handleControle}>Acessar</Button>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
}

function Tarefas(props) {

    const history = useHistory()
    const [empresas, setEmpresas] = useState([])
    const [empresaId, setEmpresaId] = useState('')
    const [estabelecimentos, setEstabelecimentos] = useState([])
    const [estabelecimentoId, setEstabelecimentoId] = useState('')
    const { aviso } = React.useContext(Context);

    const [carregando, setCarregando] = useState(false)

    useEffect(() => {
        if (props.show) {
            async function iniciar() {

                try {
                    setCarregando(true)
                    await api.get('empresa').then(response => {
                        setEmpresas(response.data.resultado)
                    })
                } catch (error) {
                    aviso('error', error)
                } finally {
                    setCarregando(false)
                }

            } iniciar();

        }

    }, [props.show])

    useEffect(() => {
        if (props.show) {
            async function iniciar() {
                setEstabelecimentoId('')
                try {
                    setCarregando(true)
                    await api.get(`estabelecimento?empresa_id=${empresaId}`).then(response => {
                        setEstabelecimentos(response.data.resultado)
                    })
                } catch (error) {
                    aviso('error', error)
                } finally {
                    setCarregando(false)
                }

            } iniciar();
        }
    }, [empresaId])


    function handleControle() {
        sessionStorage.setItem('empresaId', empresaId)
        sessionStorage.setItem('empresaName', empresas.filter(item => item.id == empresaId)[0].razao_social)
        sessionStorage.setItem('estabelecimentoId', estabelecimentoId)
        sessionStorage.setItem('estabelecimentoModalidade', estabelecimentos.filter(item => item.id == estabelecimentoId)[0].modalidade)
        sessionStorage.setItem('estabelecimentoName', estabelecimentos.filter(item => item.id == estabelecimentoId)[0].nome)
        props.onHide()
        history.push('/GerenciarAtividades')
    }


    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Tarefas/Atividades
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loading loading={carregando} message='Carregando...' />
                <div className="qual-relatorio-gerar2">
                    <div className="selecionarempresa-tarefa2">
                        <label htmlFor="selecionarempresa-tarefa2">Selecione a empresa</label>
                        <select value={empresaId} onChange={e => setEmpresaId(e.target.value)}>
                            <option value="" disabled>Selecione</option>
                            {empresas.map(empresa => (
                                <option key={empresa.id} value={empresa.id} >{empresa.razao_social}</option>
                            ))}
                        </select>
                    </div>

                    {empresaId !== '' ? <div className="selecionarempresa-tarefa2">
                        <label htmlFor="selecionarempresa-tarefa2">Selecionar organização</label>
                        <select value={estabelecimentoId} onChange={e => setEstabelecimentoId(e.target.value)}>
                            <option value="" disabled>Selecione</option>
                            {estabelecimentos.map(estab => (
                                <option key={estab.id} value={estab.id} >{estab.nome}</option>
                            ))}
                        </select>
                    </div> : null}

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={!estabelecimentoId} onClick={handleControle}>Acessar</Button>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
}

function Riscos(props) {

    const history = useHistory()
    const [empresas, setEmpresas] = useState([])
    const [empresaId, setEmpresaId] = useState('')
    const [estabelecimentos, setEstabelecimentos] = useState([])
    const [estabelecimentoId, setEstabelecimentoId] = useState('')
    const [tarefas, setTarefas] = useState([])
    const [tarefaId, setTarefaId] = useState('')
    const { aviso } = React.useContext(Context);

    const [carregando, setCarregando] = useState(false)

    useEffect(() => {
        if (props.show) {
            async function iniciar() {

                try {
                    setCarregando(true)
                    await api.get('empresa').then(response => {
                        setEmpresas(response.data.resultado)
                    })
                } catch (error) {
                    aviso('error', error)
                } finally {
                    setCarregando(false)
                }

            } iniciar();

        }

    }, [props.show])

    useEffect(() => {
        if (props.show) {
            async function iniciar() {
                setEstabelecimentoId('')
                try {
                    setCarregando(true)
                    await api.get(`estabelecimento?empresa_id=${empresaId}`).then(response => {
                        setEstabelecimentos(response.data.resultado)
                    })
                } catch (error) {
                    aviso('error', error)
                    aviso('error', error)
                } finally {
                    setCarregando(false)
                }

            } iniciar();
        }
    }, [empresaId])

    useEffect(() => {

        if (props.show) {
            async function estab() {

                try {
                    setCarregando(true)
                    if (estabelecimentoId !== '') {
                        await api.get(`tarefa?estabelecimento_id=${estabelecimentoId}`).then(response => {
                            setTarefas(response.data.resultado)
                        })
                    }
                } catch (error) {
                    aviso('error', error)
                    aviso('error', error)
                } finally {
                    setCarregando(false)
                }

            }
            estab();
        }



    }, [estabelecimentoId, empresaId]);


    function handleControle() {
        sessionStorage.setItem('empresaId', empresaId)
        sessionStorage.setItem('empresaName', empresas.filter(item => item.id == empresaId)[0].razao_social)
        sessionStorage.setItem('estabelecimentoId', estabelecimentoId)
        sessionStorage.setItem('estabelecimentoModalidade', estabelecimentos.filter(item => item.id == estabelecimentoId)[0].modalidade)
        sessionStorage.setItem('estabelecimentoName', estabelecimentos.filter(item => item.id == estabelecimentoId)[0].nome)
        sessionStorage.setItem('tarefa', tarefas.filter(item => item.id == tarefaId)[0].nome)
        sessionStorage.setItem('atividadeId', tarefaId)

        props.onHide()
        history.push('/GerenciarRiscos')
    }


    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Tarefas/Atividades
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loading loading={carregando} message='Carregando...' />
                <div className="qual-relatorio-gerar2">
                    <div className="selecionarempresa-tarefa2">
                        <label htmlFor="selecionarempresa-tarefa2">Selecione a empresa</label>
                        <select value={empresaId} onChange={e => setEmpresaId(e.target.value)}>
                            <option value="" disabled>Selecione</option>
                            {empresas.map(empresa => (
                                <option key={empresa.id} value={empresa.id} >{empresa.razao_social}</option>
                            ))}
                        </select>
                    </div>

                    {empresaId !== '' ? <div className="selecionarempresa-tarefa2">
                        <label htmlFor="selecionarempresa-tarefa2">Selecionar organização</label>
                        <select value={estabelecimentoId} onChange={e => setEstabelecimentoId(e.target.value)}>
                            <option value="" disabled>Selecione</option>
                            {estabelecimentos.map(estab => (
                                <option key={estab.id} value={estab.id} >{estab.nome}</option>
                            ))}
                        </select>
                    </div> : null}

                    {estabelecimentoId !== '' ? <div className="selecionarempresa-tarefa2">
                        <label htmlFor="selecionarempresa-tarefa2">Selecionar tarefa/atividade</label>
                        <select value={tarefaId} onChange={e => setTarefaId(e.target.value)}>
                            <option value="" disabled>Selecione</option>
                            {tarefas.map(task => (
                                <option key={task.id} value={task.id} >{task.nome}</option>
                            ))}
                        </select>
                    </div> : null}

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={!tarefaId} onClick={handleControle}>Acessar</Button>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
}

function Planos(props) {

    const history = useHistory()
    const [empresas, setEmpresas] = useState([])
    const [empresaId, setEmpresaId] = useState('')
    const [estabelecimentos, setEstabelecimentos] = useState([])
    const [estabelecimentoId, setEstabelecimentoId] = useState('')
    const [tarefas, setTarefas] = useState([])
    const [tarefaId, setTarefaId] = useState('')
    const [riscos, setRiscos] = useState([])
    const [planos, setPlanos] = useState([])
    const [riscoId, setRiscoId] = useState('')
    const [planoId, setPlanoId] = useState('')
    const { aviso } = React.useContext(Context);


    const [carregando, setCarregando] = useState(false)

    useEffect(() => {
        if (props.show) {
            async function iniciar() {

                try {
                    setCarregando(true)
                    await api.get('empresa').then(response => {
                        setEmpresas(response.data.resultado)
                    })
                } catch (error) {
                    aviso('error', error)

                } finally {
                    setCarregando(false)
                }

            } iniciar();

        }

    }, [props.show])

    useEffect(() => {
        if (props.show) {
            async function iniciar() {
                setEstabelecimentoId('')
                setTarefas([])
                try {
                    setCarregando(true)
                    await api.get(`estabelecimento?empresa_id=${empresaId}`).then(response => {
                        setEstabelecimentos(response.data.resultado)
                    })
                } catch (error) {
                    aviso('error', error)

                } finally {
                    setCarregando(false)
                }

            } iniciar();
        }
    }, [empresaId])

    useEffect(() => {

        if (props.show) {
            async function estab() {

                try {
                    setCarregando(true)
                    setTarefaId('')
                    setRiscos([])
                    if (estabelecimentoId !== '') {
                        await api.get(`tarefa?estabelecimento_id=${estabelecimentoId}`).then(response => {
                            setTarefas(response.data.resultado)
                        })
                    }
                } catch (error) {
                    aviso('error', error)

                } finally {
                    setCarregando(false)
                }

            }
            estab();
        }



    }, [estabelecimentoId]);

    useEffect(() => {

        if (props.show) {
            async function risc() {

                try {
                    setCarregando(true)
                    if (tarefaId !== '') {
                        await api.get(`risco?tarefa_id=${tarefaId}`).then(response => {
                            setRiscos(response.data.resultado)
                        })
                    }
                } catch (error) {
                    aviso('error', error)

                } finally {
                    setCarregando(false)
                }

            }
            risc();
        }



    }, [tarefaId]);

    useEffect(() => {

        if (props.show) {
            async function plan() {

                try {
                    setCarregando(true)
                    if (riscoId !== '') {
                        await api.get(`plano_acao?risco_id=${riscoId}`).then(response => {
                            setPlanos(response.data.resultado)
                        })
                    }
                } catch (error) {
                    aviso('error', error)

                } finally {
                    setCarregando(false)
                }

            }
            plan();
        }



    }, [riscoId]);


    function handleControle() {
        sessionStorage.setItem('empresaId', empresaId)
        sessionStorage.setItem('empresaName', empresas.filter(item => item.id == empresaId)[0].razao_social)
        sessionStorage.setItem('estabelecimentoId', estabelecimentoId)
        sessionStorage.setItem('estabelecimentoModalidade', estabelecimentos.filter(item => item.id == estabelecimentoId)[0].modalidade)
        sessionStorage.setItem('estabelecimentoName', estabelecimentos.filter(item => item.id == estabelecimentoId)[0].nome)
        sessionStorage.setItem('tarefa', tarefas.filter(item => item.id == tarefaId)[0].nome)
        sessionStorage.setItem('atividadeId', tarefaId)
        sessionStorage.setItem('riscoId', riscoId);
        sessionStorage.setItem('riscoName', riscos.filter(item => item.id == riscoId)[0].nome)
        sessionStorage.setItem('planoId', planoId)
        sessionStorage.setItem('planoOn', true)

        props.onHide()
        history.push('/avlrisco')
    }


    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Planos de ação
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loading loading={carregando} message='Carregando...' />
                <div className="qual-relatorio-gerar2">
                    <div className="selecionarempresa-tarefa2">
                        <label htmlFor="selecionarempresa-tarefa2">Selecione a empresa</label>
                        <select value={empresaId} onChange={e => setEmpresaId(e.target.value)}>
                            <option value="" disabled>Selecione</option>
                            {empresas.map(empresa => (
                                <option key={empresa.id} value={empresa.id} >{empresa.razao_social}</option>
                            ))}
                        </select>
                    </div>

                    {empresaId !== '' ? <div className="selecionarempresa-tarefa2">
                        <label htmlFor="selecionarempresa-tarefa2">Selecionar organização</label>
                        <select value={estabelecimentoId} onChange={e => setEstabelecimentoId(e.target.value)}>
                            <option value="" disabled>Selecione</option>
                            {estabelecimentos.map(estab => (
                                <option key={estab.id} value={estab.id} >{estab.nome}</option>
                            ))}
                        </select>
                    </div> : null}

                    {estabelecimentoId !== '' ? <div className="selecionarempresa-tarefa2">
                        <label htmlFor="selecionarempresa-tarefa2">Selecionar tarefa/atividade</label>
                        <select value={tarefaId} onChange={e => setTarefaId(e.target.value)}>
                            <option value="" disabled>Selecione</option>
                            {tarefas.map(task => (
                                <option key={task.id} value={task.id} >{task.nome}</option>
                            ))}
                        </select>
                    </div> : null}

                    {tarefaId !== '' ? <div className="selecionarempresa-tarefa2">
                        <label htmlFor="selecionarempresa-tarefa2">Selecionar risco</label>
                        <select value={riscoId} onChange={e => setRiscoId(e.target.value)}>
                            <option value="" disabled>Selecione</option>
                            {riscos.filter(item => item.abrir_plano_acao !== "Não").map(risk => (
                                <option key={risk.id} value={risk.id} >{risk.nome}</option>
                            ))}
                        </select>
                    </div> : null}

                    {riscoId !== '' ? <div className="selecionarempresa-tarefa2">
                        <label htmlFor="selecionarempresa-tarefa2">Selecionar plano</label>
                        <select value={planoId} onChange={e => setPlanoId(e.target.value)}>
                            <option value="" disabled>Selecione</option>
                            {planos.map(plan => (
                                <option key={plan.id} value={plan.id} >{plan.o_que}</option>
                            ))}
                        </select>
                    </div> : null}

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={!riscoId} onClick={handleControle}>Acessar</Button>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );
}



export default Sidebar;

